import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Row, Card, Table } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';

const RevGuidesTeamBuilding: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Team Building</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_teams.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Reverse:1999 Team Building</h1>
          <h2>
            Everything you need to know about building teams in Reverse: 1999 -
            both early and meta teams.
          </h2>
          <p>
            Last updated: <strong>11/04/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Foreword" />
              <p>
                Special thanks to <strong>Liriell and Gordan</strong> who worked
                tirelessly on this guide.
              </p>
              <p>
                If you're looking for teams that you can use in the{' '}
                <strong>Mane's Bulletin (aka Raid Boss mode)</strong>, check our
                other guide dedicated to this mode:
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Mane's Bulletin (Raid Boss)"
                  link="/re1999/guides/mane-bulletin"
                  image={
                    <StaticImage
                      src="../../../images/reverse/categories/category_raid.jpg"
                      alt="Team Building"
                    />
                  }
                />
              </Row>
              <SectionHeader title="Introduction" />
              <p>
                In this guide, we offer a few options and examples of pre-made
                teams and how to unleash their potential.{' '}
                <strong>
                  Please take note that these are merely guidelines, and not
                  hard rules.
                </strong>{' '}
                Reverse: 1999 has nearly endless options and variations when it
                comes to team building, especially if we take cyclical endgame
                content like UTTU Challenge and Limbo who are constantly
                bringing new mechanics into play. These are also only the most
                common “carries”, or Main Damage Dealers. If you don’t see a
                character you like here, that doesn’t mean they’re useless; just
                check the{' '}
                <strong>
                  <Link to="/re1999/tier-list">Tier List</Link>
                </strong>{' '}
                or the specific character page to see their role and tags, and
                adjust the team as needed in case you want to use a specific
                character or don’t have one of the suggested characters for the
                combos below.
              </p>
              <p>
                If you’re still lost on some of the terms, we highly recommend
                you check the <strong>[Beginner Guide]</strong> first.
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={3}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Beginner guide"
                  link="/re1999/guides/beginner-guide"
                  image={
                    <StaticImage
                      src="../../../images/reverse/categories/category_beginner.png"
                      alt="Beginner guide"
                    />
                  }
                />
              </Row>
              <p>For a short review on how to build a team:</p>
              <ul>
                <li>
                  <strong>Pick a main damage dealer</strong>. The stage will
                  usually say the recommended Afflatus to use, and you can also
                  check the enemy info as well as their Mental and Reality DEF.
                </li>
                <li>
                  <strong>Pick a support.</strong> A support in this case refers
                  to someone who will make your main damage dealer shine even
                  more. Sometimes they can even be a secondary damage dealer,
                  but often will be someone who also has some utility within
                  their kit, be it in the shape of buffs, debuffs, crowd-control
                  or other special effects (such as Moxie gain, or deplete Moxie
                  from enemies).
                </li>
                <li>
                  <strong>
                    Pick either a second support or a Survival character.
                  </strong>{' '}
                  At first, healing won’t be a priority, but the further the
                  game progresses, the trickier it may become to keep you
                  characters alive. Most healers and shielders also offer some
                  utility or damage, thus completing Reverse:1999 main triad of
                  roles.
                </li>
              </ul>
              <h5>What about the fourth slot?</h5>
              <p>
                The Substitute slot (or in some stages the fourth character in
                battle) is not used often, as the ideal is that your characters
                don’t die. You can use this slot to help raise the bond of a
                character (either for the lore or for the extra clear drops) or
                you can pick one of the alternative options already suggested in
                the teams below. We also have added a list of Flex characters
                who can come in handy for most situations and to replace others.
                You can use that as a reference when deciding who to bring for
                your fourth slot or if you’re missing one or more of the
                characters suggested in the pre-made teams.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Global Server (Early)" />
              <div className="team-box">
                <h5 className="beast">
                  HOTSAUCE EXPERTS <span className="tag">[Standard]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="centurion"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="an-an-lee"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="medicine-pocket"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Centurion is a great Carry that can cover both single
                      target and AoE damage using her Insights/Skills or
                      Ultimate respectively. She also gains Moxie incredibly
                      quickly, making it really easy to optimize her damage
                      output. Though she isn't what she used to be, she is
                      really solid for pushing early content and will net you a
                      smooth ride through the early and mid-game.
                    </li>
                    <li>
                      An-An Lee provides +DMG Dealt while Medicine Pocket
                      inflicts +DMG Taken, which helps Centurion squeeze that
                      extra bit of damage from her already high-power arsenal.
                    </li>
                    <li>
                      Offense isn’t the team’s only strong suit. Centurion heals
                      herself when she casts her Ultimate and inflicts
                      [Weakness] to reduce enemy damage. Meanwhile, An-An Lee
                      routinely applies -DMG Taken to all allies and Medicine
                      Pocket provides healing and [Sturdiness].
                    </li>
                    <li>
                      This team is extremely versatile, powerful in the
                      offensive department and the defensive department.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-box">
                <h5 className="beast">
                  PHYSICAL BEAST <span className="tag">[BUDGET]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="centurion"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="bkornblume"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="balloon-party"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="dikke"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="la-source"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Centurion is a great Carry that can cover both single
                      target and AoE damage using her Insights/Skills or
                      Ultimate respectively. She also gains Moxie incredibly
                      quickly, making it really easy to optimize her damage
                      output. Though she isn't what she used to be, she is
                      really solid for pushing early content and will net you a
                      smooth ride through the early and mid-game.
                    </li>
                    <li>
                      Bkornblume is the obvious non 6-star choice here, having
                      access to a powerful -Reality DEF and +DMG Taken debuff
                      for opponents that Centurion can maximize her damage off
                      of.
                    </li>
                    <li>
                      While less defensively suited than the Whale version of
                      this team due to Bkornblume’s natural squishiness, adding
                      a unit like Balloon Party or Dikke (or even La Source) can
                      help keep the team in the fight.
                    </li>
                    <li>
                      This team is strong offensively, with Centurion and
                      Bkornblume both having access to high-damage options and a
                      bit of enemy control through Centurion’s [Weakness] debuff
                      and Bkornblume’s crowd control."
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-box">
                <h5 className="star">
                  TSAR BOMBA <span className="tag">[Standard]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="lilya"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="regulus"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="voyager"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="an-an-lee"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="medicine-pocket"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Utilizes Voyager’s [Confusion] and Medicine Pocket’s +DMG
                      Taken debuffs to greatly increase Lilya/Regulus’s damage
                      output and help them increase their Crit chance when they
                      need to. This is especially potent in the early game when
                      Resonance and Psychubes don't provide all the required
                      Crit stats yet.
                    </li>
                    <li>
                      The team is kept safe with Voyager’s [Counter] and [Chorus
                      Ensemble] effects while Medicine Pocket provides direct
                      healing and [Sturdiness] for consistent damage reduction.
                    </li>
                    <li>
                      If you’re feeling ballsy you can try swapping Medicine
                      Pocket with An-An Lee, this will forgo healing for +DMG
                      Dealt (further increasing the damage output) and -DMG
                      Taken (for additional defense)."
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-box">
                <h5 className="star">
                  BUDGET STAR <span className="tag">[Standard]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="lilya"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="sonetto"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="erick"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="dikke"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="tennant"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <ul>
                      <li>
                        Sonetto provides consistent +DMG Dealt and -DMG Taken
                        buffs to help Lilya/Regulus optimize their damage under
                        the suboptimal circumstances. Meanwhile Tennant further
                        increases Lilya’s damage with her [Diamond Bullet] buff
                        reducing an enemy’s Reality DEF while Dikke is otherwise
                        used for the actual healing rather than shielding.
                      </li>
                      <li>
                        Tennant’s incredibly consistent shielding and Sonetto’s
                        [Disarm] help keep the team safe as they rushes to get
                        their Ultimates online. Do note that shields alone are
                        hard pressed to sustain a team, and in some cases it
                        might be better to run Dikke for some actual proper
                        sustain.
                      </li>
                      <li>
                        Erick is an honorable mention for her potent -Crit
                        Resist and -Crit DEF debuffs, which can help Lilya and
                        Regulus start landing crits even in the early game.
                        Since Erick is a 4✦, you are very likely to have her."
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>
              <div className="team-box">
                <h5 className="mineral">
                  Old Times' Sake <span className="tag">[Standard]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="eternity"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="an-an-lee"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="medicine-pocket"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="ms-newbabel"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Eternity, while playing in a full team, needs allies that
                      can help her stay alive until she hits 5 stacks of her
                      [Blood of Longevity] passive. She prides herself on
                      survivability, but this causes her damage output to be
                      lower than other high-rarity Carries.
                    </li>
                    <li>
                      An-An Lee is utilized for their +DMG Dealt and -DMG Taken
                      buffs, which synergize well with Eternity. Their own
                      surprisingly high damage output can also make up for what
                      Eternity herself lacks.
                    </li>
                    <li>
                      New Babel is an option if you prefer [Shield]s or are on a
                      stage where the outgoing damage is consistently high. If
                      you aren't comfortable with just NewBabel, instead you can
                      use Medicine Pocket to provide actual healing."
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-box">
                <h5 className="mineral">
                  Budget Mineral <span className="tag">[Standard]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="eternity"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="sonetto"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="balloon-party"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Eternity, while playing in a full team, needs allies that
                      can help her stay alive until she hits 5 stacks of her
                      [Blood of Longevity] passive. She prides herself on
                      survivability, but this causes her damage output to be
                      lower than other high-rarity Carries.
                    </li>
                    <li>
                      Sonetto is utilized for their +DMG Dealt and -DMG Taken
                      buffs, which synergize well with Eternity. Her own decent
                      damage output and access to [Disarm] also help mitigate
                      some of Eternity's problems.
                    </li>
                    <li>
                      Balloon Party has the ability to reduce incoming damage
                      while providing healing, which also synergize excellently
                      with Eternity’s needs."
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-box">
                <h5 className="plant">
                  Low-Star <span className="tag">[Standard]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="eagle"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="sonetto"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="erick"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="la-source"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Eagle is the queen of low-rarity Carries, providing insane
                      burst damage with her Ultimate having a 100% crit chance
                      at Portray 4 and her own [Sense Weakness] debuff
                      decreasing an enemy’s Reality and Crit DEF. Other options
                      include Pavia for AoE DMG or Matilda for a moreso Crit
                      oriented team.
                    </li>
                    <li>
                      Sonetto is present for her potent +DMG Dealt buff helping
                      Eagle and herself deal excellent damage.
                    </li>
                    <li>
                      La Source’s healing and control via -Moxie on enemies
                      makes her a really solid low-rarity healer, while
                      Sonetto’s [Disarm] and -DMG Taken further help Eagle tank
                      any hits that may come her way.
                    </li>
                    <li>
                      Erick is an honorable mention for her -Crit Resist and
                      -Crit DEF, which can help Eagle land crits."
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-box">
                <h5 className="star">
                  THEATRE!!! <span className="tag">[Standard]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="charlie"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="baby-blue"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="medicine-pocket"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="dikke"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Charlie is considered one of the best 5✦ Carries in the
                      game. At Portray 3, where her Skill’s increased damage
                      requirements become more lenient, she starts dealing more
                      damage (though marginally) than even Lilya.
                    </li>
                    <ul>
                      <li>
                        Note: While Charlie deals increased damage when she has
                        buffs on her, her Insights grant her her own buffs.
                        Since buffs can’t overlap, bringing anyone with +DMG
                        Dealt is not recommended.
                      </li>
                    </ul>
                    <li>
                      Baby Blue’s decent -Mental DEF and +DMG Taken debuffs here
                      synergize nicely with Charlie’s kit.
                    </li>
                    <li>
                      We selected Medicine Pocket or Dikke for their healing
                      (and [Studiness] + [Daze] in Medicine Pocket’s case) to
                      keep the extremely squishy Charlie alive and above 50% HP
                      so she can trigger her Insight 3.
                    </li>
                    <li>
                      The reason why Charlie isn’t typically paired with Crit
                      supports unlike her Star Afflatus peers is because Charlie
                      has a deceptively abysmal Crit Tech, having only a 6.56%
                      base Crit Rate at Insight 2 versus Lilya/Regulus’ massive
                      15.56% base Crit Rate."
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-box">
                <h5 className="plant">
                  Rock Solid <span className="tag">[Standard]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="satsuki"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="druvis-iii"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="la-source"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="medicine-pocket"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Satsuki and Druvis form a Carry pair here, with Satsuki
                      jumping in on Druvis' consistent application of [Petrify]
                      to extend its duration with [Rigidity]. As all of the
                      units deal Mental DMG, you won’t have to worry about
                      enemies breaking out of the control early.
                    </li>
                    <li>
                      While offensively being lackluster, the constant [Petrify]
                      and decent damage coming from both Satsuki and Druvis
                      ensures that, once the combo is going, it is very hard for
                      enemies to break out of.
                    </li>
                    <li>
                      Druvis and La Source are the defensive units in this team.
                      Druvis provides the team with DMG Heal and Leech Rate via
                      her [Circle of Life] buff at Insight 3, which also
                      triggers her Insight 1, while La Source’s consistent
                      healing makes sure that the team is topped off while they
                      execute their combo.
                    </li>
                    <li>
                      There isn’t much in the way of offensive buffing or
                      debuffing, resulting in somewhat mediocre damage from
                      Satsuki (Druvis buffs herself with Insight 1), but the
                      team is definitely a useful pocket pick for if a certain
                      annoying stage is holding you back.
                    </li>
                    <li>
                      The reason why this team isn’t commonly built any more is
                      because Jessica shook the Plant meta into a more damage
                      focused attitude using [Poison] to nuke waves of enemies.
                      Despite this, this team remains a solid option and is
                      probably one of the better [Control] based teams
                      available."
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-box">
                <h5 className="star">
                  SLEEPGE <span className="tag">[Standard]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="voyager"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="ms-newbabel"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="balloon-party"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Mostly a meme team, it revolves around using each
                      Arcanist’s respective [Counter] spells to set up rounds of
                      passive damage from taking enemy attacks.
                    </li>
                    <li>
                      Voyager is used here as the main Carry as her Ultimate
                      “Galactic On Strings” is the only Ultimate in the group
                      with good damage, she is also known for having
                      surprisingly high damage output. The other offensive side
                      of this team is the [Counter] skills from all involved
                      Arcanists dealing continuous damage.
                    </li>
                    <li>
                      Defensively the team is actually rather solid. Voyager’s
                      “Chorus Ensemble” and Ultimate can inflict [Seal],
                      [Silence] and -Moxie. NewBabel uses [Taunt] and [Shield]
                      to keep the team safe from direct damage and Balloon Party
                      provides -DMG Taken and healing.
                    </li>
                    <li>
                      If you decide to build this team, please know that it is
                      not at all intended to be an optimal strategy for
                      anything. Though it is powerful defensively."
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Global Server" />
              <div className="team-box">
                <h5 className="beast">
                  Sneaks <span className="tag">[Standard]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="melania"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="an-an-lee"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="medicine-pocket"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Melania is a single-target oriented Carry that thrives off
                      of hoarding AP as much as she can. Her damage is primarily
                      dealt through her Ultimate, utilizing the [Skillful Thief]
                      buff to quickly cycle it and the slowly-stacking
                      [Established Plan] buff from her Insights to continuously
                      rack up damage. At maximum potency, her Ultimate can hit
                      for massive Mental DMG.
                    </li>
                    <li>
                      An-an Lee and Medicine Pocket here are the perfect
                      offensive pairing for Melania to take her turns. Both are
                      AP-generous with An-an Lee auto-upgrading her cards and
                      Medicine Pocket having spammable skills. An-an Lee also
                      provides extra +DMG Dealt that pairs well with Medicine
                      Pocket’s +DMG Taken debuff, which greatly benefits
                      Melania’s single-target damage output.
                    </li>
                    <li>
                      Medicine Pocket is the defensive cornerstone here,
                      providing high healing output and layers of [Sturdiness]
                      to keep the squishy Melania alive. An-an Lee too helps
                      with this by consistently inflicting [Daze] to keep
                      enemies from taking actions.
                    </li>
                    <li>
                      While this team is almost entirely single-target, it
                      absolutely excels at its job. Mainly, high 1-target damage
                      that only improves as the fight goes on (which will
                      inevitably become better and better when more bosses are
                      released such as Raids)."
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-box">
                <h5 className="beast">
                  JAIL TIME <span className="tag">[BUDGET]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="melania"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="diggers"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="sonetto"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="dikke"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Melania is a single-target oriented Carry that thrives off
                      of hoarding AP as much as she can. Her damage is primarily
                      dealt through her Ultimate, utilizing the [Skillful Thief]
                      buff to quickly cycle it and the slowly-stacking
                      [Established Plan] buff from her Insights to continuously
                      rack up damage. At maximum potency, her Ultimate can hit
                      for massive Mental DMG.
                    </li>
                    <li>
                      Diggers is here as the 5-star unit from Pickles’ banner as
                      the two aren’t very far apart. While he may look like an
                      inferior Baby Blue, in our opinion, he is the perfect
                      budget support for Melania. His universal -DEF debuff
                      allows Melania to deal her damage while Diggers’ spammable
                      debuffs make him relatively AP-generous.
                    </li>
                    <li>
                      Diggers capitalizes on Melania being almost entirely
                      single-target (with exception of “Clockwork Rat
                      Infestation for self-healing) to inflict the [Nightmare]
                      debuff. He also provides further defensive support by
                      inflicting [Weakness] and [Terror], helping Melania stay
                      alive.
                    </li>
                    <li>
                      Dikke is used here as the single-target damage focused
                      healer, further enhancing the potency of the [Nightmare]
                      debuff while providing a bit of healing on top of Diggers’
                      Insights and cleansing for when it's needed.
                    </li>
                    <li>
                      This team is single-target focused to make the most out of
                      [Nightmare] while letting Melania run her rounds. It is
                      also incredibly heavy on Moxie control, with the [Terror]
                      debuff and Melania’s Moxie stealing ensuring that enemies
                      will never get to activate their Ultimates."
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-box">
                <h5 className="mineral">
                  DOGWALKING <span className="tag">[Standard]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="melania"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="a-knight"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="kaalaa-baunaa"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="pickles"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="medicine-pocket"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="tooth-fairy"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Due to Pickles’ universal +DMG Dealt buff on “Thus Spoke
                      Pickles” he slots into a lot of different team comps. His
                      extremely AP-generous playstyle and swift Moxie generation
                      allows him to both keep up with Carries that cycle their
                      Ultimates quickly and those that want AP-generous
                      teammates. Besides, his own damage output isn’t too bad,
                      as he gains increased damage or Penetration Rate while he
                      is in the [Clarified Topic] state.
                    </li>
                    <li>
                      The +DMG Dealt buff from his Ultimate is long-lasting and
                      buffs damage universally, making Pickles usable in both
                      Mental and Reality damage teams. Medicine Pocket is here
                      to provide the +DMG Taken debuff and also their
                      AP-generous playstyle.
                    </li>
                    <li>
                      Medicine Pocket is the defensive anchor for this team,
                      utilizing their high healing output and constant
                      application of [Sturdiness] to keep Pickles and their
                      Carry companion up and fighting. They also provide extra
                      puppies for Pickles to play with, how kind.
                    </li>
                    <li>
                      For obvious reasons, Tooth Fairy is also an applicable
                      healer here. Her Crit debuffs are great, her healing
                      output is insane and she is one of the few units with an
                      AoE cleanse."
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-box">
                <h5 className="plant">
                  CHEMICAL WARFARE <span className="tag">[Standard]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="jessica"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="an-an-lee"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="bkornblume"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="kanjira"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="sotheby"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Jessica is the best Plant Carry we have at the moment (at
                      least until Marcus releases), with her capitalizing off of
                      [Poison] stacks on enemies to massively increase her own
                      damage. Her Skill 1, for example, can hit for a solid 625%
                      Reality DMG if the targeted enemy is carrying 3 stacks of
                      [Poison] (that’s basically an ST Ultimate multiplier).
                    </li>
                    <li>
                      Note that Jessica’s damage caps out at 3 stacks of
                      [Poison], which she struggles to inflict on her own with
                      her Insight 1, 3 and her Ultimate. Due to this, either
                      Sotheby or Kanjira are required to faciliate this until
                      further Poison Supports are released.
                    </li>
                    <li>
                      Due to the importance of Sotheby as a key unit you can
                      also slot her into the Support role (this is easier in
                      4-man content), though this will make you lose out on
                      other powerful synergies such as Bkornblume for the
                      -Reality DEF and An-an Lee for her powerful offensive and
                      defensive buffs.
                    </li>
                    <li>
                      More than most, Jessica capitalizes off of high-rank
                      cards, so utilizing An-An Lee to set up 3-star cards only
                      to reshuffle them using Tuning is a potent way of
                      facilitating both Jessica’s damage and Sotheby’s
                      AP-greediness.
                    </li>
                    <li>
                      Besides Sotheby, your best pick in terms of healers is
                      Tooth Fairy. Together with Kanjira's [Confusion], Tooth
                      Fairy can set Jessica up to viably Crit."
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-box">
                <h5 className="mineral">
                  HONORS STUDENTS <span className="tag">[Standard]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="kaalaa-baunaa"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="pickles"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="an-an-lee"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="tooth-fairy"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="balloon-party"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Kaalaa Baunaa comes in with very high damage that has to
                      ramp up over the course of a fight. Proper use of her
                      [Planet] and [Divination] effects in combination with her
                      Ultimate is required to make the most of her, such as
                      deciding between self-sustain (Mars) or damage (Saturn).
                      This, combined with her kit’s focus around spamming her
                      Ultimate to trigger its extra effects, causes Kaalaa
                      Baunaa to eat a lot of AP.
                    </li>
                    <li>
                      Pickles is almost made to support Kaalaa Baunaa. He only
                      has to take an action every other turn to proc
                      [Proposition of Clarification], which grants him all he
                      needs (extra damage, additional Moxie), meaning Kaalaa
                      Baunaa can take as many actions as she needs. His Ultimate
                      +DMG Dealt also lines up pretty well with Kaalaa Baunaa’s
                      rotation, meaning she is always buffed when she needs to
                      be.
                    </li>
                    <li>
                      Tooth Fairy here is the standard healer option. Black
                      Dwarf has a surprising Crit Tech, so Tooth Fairy can
                      super-buff that with her powerful Crit debuffs. Her high
                      healing output and cleansing also makes sure that Kaalaa
                      Baunaa can keep taking as much AP as she needs to.
                    </li>
                    <li>
                      More than most, Jessica capitalizes off of high-rank
                      cards, so utilizing An-An Lee to set up 3-star cards only
                      to reshuffle them using Tuning is a potent way of
                      facilitating both Jessica’s damage and Sotheby’s
                      AP-greediness.
                    </li>
                    <li>
                      Balloon Party can also be utilized to occasionally proc
                      her “Coughing Weirdo” [Counter] and then going AFK,
                      allowing Kaalaa Baunaa to use all the AP she needs to
                      cycle her Ultimate.
                    </li>
                    <li>
                      An-An Lee can also be slotted in instead of Pickles, as
                      she has a similar sort of AP-Positive kit."
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="team-box">
                <h5 className="beast">
                  Steal Your SO <span className="tag">[Standard]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="melania"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="shamane"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="medicine-pocket"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="tooth-fairy"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Melania is a viable option in the Carry slot. She has
                      extremely high single-target damage and a decent Crit
                      Tech, so she synergizes well with both Shamane and Tooth
                      Fairy.
                    </li>
                    <li>
                      Pickles is almost made to support Kaalaa Baunaa. He only
                      has to take an action every other turn to proc
                      [Proposition of Clarification], which grants him all he
                      needs (extra damage, additional Moxie), meaning Kaalaa
                      Baunaa can take as many actions as she needs. His Ultimate
                      +DMG Dealt also lines up pretty well with Kaalaa Baunaa’s
                      rotation, meaning she is always buffed when she needs to
                      be.
                    </li>
                    <li>
                      Shamane is used as support, as he has a universal debuff
                      (-Reality and Mental DEF), and a somewhat AP-generous
                      playstyle, being able to store Moxie he gains to cast his
                      Ultimate in quick succession. This allows him to also
                      perform quite well as both a debuffer and a semi-Carry,
                      with his damage output being surprisingly high (a similar
                      situation to a character like Bkornblume).
                    </li>
                    <li>
                      Tooth Fairy’s absurd Crit debuffs help virtually any Carry
                      land crits. But Medicine Pocket can be utilized for their
                      universal +DMG Taken buff and the AP-generosity on their
                      Ultimate.
                    </li>
                    <li>
                      Defensive options include Tooth Fairy for the high healing
                      output and cleansing or Medicine Pocket for the similarly
                      high healing and [Sturdiness] application to keep their
                      team safe.
                    </li>
                    <li>
                      Generally speaking, Shamane can be used in any team you’d
                      like him to work in. Due to his debuff being universal,
                      his surprisingly high personal damage and the AP-generous
                      element of his kit in [Nature’s Blessing], there are very
                      few teams that Shamane cannot slot into."
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className="team-box">
                <h5 className="star">
                  MATH CLASS <span className="tag">[STANDARD]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="37"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="6"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="lilya"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="ms-newbabel"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="tooth-fairy"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      37 capitalizes off of the extra action on Lilya’s
                      “Crosswind Takeoff” ability to quickly stack up her
                      [Eureka] buff and trigger the [Supplementary Formula]
                      attack as often as possible.
                    </li>
                    <li>
                      Exploits Tooth Fairy’s powerful -Crit Resist, -Crit DEF
                      and [Confusion] debuffs to basically guarantee any
                      decently built Crit Carry landing crits when they need to
                      deal damage.
                    </li>
                    <li>
                      The team is kept safe by Tooth Fairy’s potent healing via
                      “Lullaby”, Insight 3 and the team-wide cleansing on her
                      Ultimate.
                    </li>
                    <li>
                      If the team lacks defensive power, bringing NewBabel into
                      the mix instead of Lilya will help with survivability,
                      albeit at the cost of the team’s overall damage output. In
                      exchange, with how NewBabel functions, she can also help
                      charge [Supplementary Formula] faster.
                    </li>
                    <li>
                      You really can’t go wrong with either variation of the
                      team, it more so hangs on how comfortable you are swapping
                      between the defensive and ap-generous Regulus and the more
                      aggressive Lilya (especially because Lilya provides no
                      real support)."
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="CN Server" />
              <div className="team-box">
                <h5 className="beast">
                  Good Heaven!!! <span className="tag">[STANDARD]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="spathodea"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="an-an-lee"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="6"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="tooth-fairy"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="medicine-pocket"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Spathodea rides a fine line between Centurion and Melania,
                      focusing instead on high bursts of damage with downtime in
                      between as opposed to other Carries’ more rapid
                      playstyles. She excels when being provided ways to upgrade
                      her cards due to the increased crit rate and [Burn]
                      application that comes with that and Crit-based supports.
                    </li>
                    <li>
                      An-an Lee or 6 are primarily here for their ease of use,
                      considering Spathodea’s AP-greedy playstyle, and their
                      ability to either upgrade their own cards to shuffle with
                      Tuning or upgrading Spathodea’s cards specifically with
                      [Empower Incantation I]. In addition, they can provide
                      powerful offensive buffs or debuffs that Spathodea scales
                      extremely well with.
                    </li>
                    <li>
                      The decision between Medicine Pocket and Tooth Fairy
                      depends on the Portray you have Spathodea at. In her early
                      Portrays, Spathodea might need some help with landing
                      crits, which are problems greatly alleviated by Tooth
                      Fairy’s presence. However, from Portray 3 onward,
                      Spathodea is easily capable of maxing out her own Crit
                      Rate, and with no crit conversion, Medicine Pocket’s
                      consistent +DMG Taken debuff provides more damage to her
                      overall."
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-box">
                <h5 className="beast">
                  JIU NATIONAL <span className="tag">[Standard]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="jiu-niangzi"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="isolde"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="37"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="6"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="tooth-fairy"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Jiu Niangzi is the undisputed Queen of DPM, dealing
                      significantly more damage than literally every other Carry
                      in the game. Though she provides only damage, given the
                      current scaling of content, she breezes through basically
                      any challenge, and has been seen hitting SSS rank on Raids
                      in a team composed of just her and 37. Much of her damage
                      is dependent on her ability to generate [Liquor], for
                      which she needs allies that can apply continuous/various
                      buffs.
                    </li>
                    <li>
                      Isolde is used for her synergy with Jiu, who attacks a lot
                      (and thus applies Burn consistently). Where Jiu provides
                      Isolde with rapidly amounts of Heat, Isolde provides Jiu
                      with potent -Crit/Reality DEF (both of which apply),
                      additiona damage from [Intermezzo] and consistent +DMG
                      Dealt buffs from the Ultimate and [Finale]. Initially 37
                      was used in this spot, and while she is still viable, 37
                      has problems that get solved by the Jiu National team,
                      whereas Isolde actually provides additional things to the
                      team.
                    </li>
                    <li>
                      With his release, 6 has become a staple (almost must) pick
                      in Raids. His sheer number of buffs, debuffs and decent
                      single-target damage together with his access to giving
                      his Carry [Incantation Empowerment I] to upgrade their
                      cards allows 6 to occupy a spot for just about every Raid
                      team. In this team specifically, his Insights and “Duties
                      and Obligations” cards allow him to apply several unique
                      buffs that feed Jiu her [Liquor].
                    </li>
                    <li>
                      Tooth Fairy here is the standard healer option. Jiu
                      Niangzi has some synergy with Crit-oriented supports, and
                      given just how much damage she does, Tooth Fairy’s highly
                      consistent crit debuffs serve as a great way to enhance
                      her damage output even further."
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-box">
                <h5 className="beast">
                  One Punch Woman <span className="tag">[STANDARD]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="spathodea"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="isolde"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="tooth-fairy"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Spathodea is a high burst single-target damage Carry that
                      cares about 2 things: Crit and Burn. She has among the
                      highest burst damage in the game, being able to break into
                      the 100k in a single hit (under the right circumstances).
                    </li>
                    <li>
                      Initially, Spathodea saw little use in standard 3-man
                      teams because you were pushed into using Ulu (a very bad
                      and also AP-negative Support) to facilitate Spathodea's
                      damage. This problem has since be alleviated by Isolde's
                      release, whose -Crit/Reality DEF debuffs, +DMG Dealt buffs
                      and extremely consistent application of Burn give
                      Spathodea all she needs to excel in standard comps.
                    </li>
                    <li>
                      Tooth Fairy is naturally here as a defensive anchor for
                      the squishy Spathodea and Isolde. Besides her amazing
                      burst healing, her -Crit RES/DEF debuffs and [Confusion]
                      help to further enhance Spathodea's' damage output."
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-box">
                <h5 className="beast">
                  Girls With Hyperfixations{' '}
                  <span className="tag">[STANDARD]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="37"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="isolde"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="tooth-fairy"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      37 is a high AoE DMG Crit Carry that relies on Crit
                      support and generates Eureka from Follow-Up Attacks to
                      continuously cast [Supplementary Formula]. A majority of
                      her damage is in her ability to Crit on Genesis DMG, which
                      usually can't Crit. What 37 needs most is a Support that
                      can also deal damage, as without her Portrays she doesn't
                      fully work as a Carry.
                    </li>
                    <li>
                      Isolde is an almost perfect Support for 37. Though the
                      -Reality DEF doesn't apply to her, the massive -Crit DEF
                      debuff (and +DMG Dealt buffs) does, and her constant
                      [Intermezzo] Follow-Up attacks also help with generating
                      Eureka for 37's [Supplementary Formula] casts.
                    </li>
                    <li>
                      Tooth Fairy is naturally here as a defensive anchor for
                      the squishy 37 and Isolde. Besides her amazing burst
                      healing, her -Crit RES/DEF debuffs and [Confusion] help to
                      further enhance 37 and Isolde's damage output."
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-box">
                <h5 className="beast">
                  Reading Club <span className="tag">[STANDARD]</span>
                </h5>
                <div className="team">
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="marcus"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="dps">Damage Dealer</h6>
                  </div>
                  <div className="role mid">
                    <div className="characters small">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="6"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="an-an-lee"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="support">Support</h6>
                  </div>
                  <div className="role">
                    <div className="characters">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="druvis-iii"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="sotheby"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <h6 className="sustain">Sustain</h6>
                  </div>
                </div>
                <div className="info">
                  <h6>TEAM EXPLANATION</h6>
                  <ul>
                    <li>
                      Marcus is the new kid on the block who brought books and a
                      glock. She has an extremely high damage ceiling and
                      focuses on upgrading her own cards by consuming Eureka
                      which she gains when a target with [Annotation] takes an
                      action. However, a part of kit is locked behind having 3
                      Plant, Spirit and/or Intelligence allies (including
                      herself). With no healers from Spirit and Plant, you rely
                      on Druvis' [Circle of Life], Sotheby or La Source (none of
                      which synergize with her) to get her to do any meaningful
                      cycling.
                    </li>
                    <li>
                      6 is present because his consistent application of
                      [Incantation Empowerment] grealy improves Marcus' damage
                      potential as she wants to cast her cards at rank 2 or
                      higher. A different option for Support includes An-An Lee,
                      whose self-upgrading system can provide high rank cards as
                      food for shuffling with Tuning.
                    </li>
                    <li>
                      Druvis forms the awkward defensive cornerstone of the
                      team, relying almost exclusively on her [Circle of Life]
                      buff to provide Marcus with Leech Rate and [Petrify] to
                      mitigate incoming damage through crowd control. The reason
                      Sotheby isn't used frequently is because her AP-Negative
                      playstyle makes her team-up with Marcus extremely awkward
                      and La Source simply doesn't keep up as far as content
                      goes."
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Flex characters" />
              <Table striped bordered responsive className="flex-chars">
                <thead>
                  <tr>
                    <th>CHARACTER</th>
                    <th>ROLE AND EXPLANATION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="left">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="a-knight"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </td>
                    <td>
                      A Knight is a versatile sub Damage Dealer. Use him to
                      “clean up” enemies who are left with low HP to enable him
                      to charge his ultimate faster, and give all allies a DMG
                      Bonus of 50%.
                    </td>
                  </tr>
                  <tr>
                    <td className="left">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="pickles"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </td>
                    <td>
                      Very AP-generous, good damage, has Dispel and a buff of
                      +30% DMG dealt for two rounds to all allies on his
                      ultimate. This good boy has it all and will be your best
                      friend wherever you go.
                    </td>
                  </tr>
                  <tr>
                    <td className="left">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="sonetto"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </td>
                    <td>
                      Sonetto’s Buffs and Disarm are welcome in any team, and
                      her own damage is decent, especially against large groups
                      of enemies.
                    </td>
                  </tr>
                  <tr>
                    <td className="left">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="balloon-party"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </td>
                    <td>
                      Another character who has it all, Balloon Party can offer
                      reliable healing, counter and damage reduction. Bein a
                      5-star also makes her more accessible than the 6-star
                      healers, while still fitting in almost any team.
                    </td>
                  </tr>
                  <tr>
                    <td className="left">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="la-source"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </td>
                    <td>
                      La Source is a very cheap to raise Healer but very
                      effective on her job. Being a low rarity, it’s very easy
                      to get her Portray at max, and on top of reliable Mass
                      Healing, a P5 La Source can completely deplete all 5 Moxie
                      of the target with her ult, not only cancelling the
                      enemy’s ult, but also giving the team plenty of time
                      before that bar raises again.
                    </td>
                  </tr>
                  <tr>
                    <td className="left">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="leilani"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </td>
                    <td>
                      Another inexpensive character, obtained for free early on
                      in the story. Leilani has a Moxie gain mechanic built in
                      both on her passive and her Ultimate, making her viable
                      for sacrifice strats or to use as a substitute and turn
                      the tide of the battle.
                    </td>
                  </tr>
                  <tr>
                    <td className="left">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="x"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </td>
                    <td>
                      X has a variety of utility tools at his disposal, being
                      able to dispel [Pos Stats], [Stats Up], [Counter], lower
                      enemy Moxie by 2 and still benefit further just from doing
                      his job, as his passive enables him to apply [Silence] and
                      later on at i3, [Disconcert]. The best part is that
                      usually these tools are needed more often against elite
                      enemies or bosses, and while X doesn’t have a good damage
                      output on regular scenarios, most elite enemies and bosses
                      also happen to be from the Spirit afflatus, the only kind
                      a character like X has DMG advantage against.
                    </td>
                  </tr>
                  <tr>
                    <td className="left">
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug="twins-sleep"
                          mode="icon"
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </td>
                    <td>
                      A low rarity character with a wide range of debuffs who is
                      very beneficial to Mental DMG teams and Crit. Oriented
                      Teams. Twins Sleep can reliably lower all enemies’ Damage
                      and Critical DEF, as well as lower a single target’s
                      Mental DEF or inflict [Nightmare] status. Keep an eye for
                      their passive “Arcane Stamp” that triggers every other
                      round, as it makes any debuff they apply last an extra
                      round.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Global Server (Early)</a>
                </li>
                <li>
                  <a href="#section-2">Global Server</a>
                </li>
                <li>
                  <a href="#section-3">CN Server</a>
                </li>
                <li>
                  <a href="#section-4">Flex characters</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default RevGuidesTeamBuilding;

export const Head: React.FC = () => (
  <Seo
    title="Team Building | Reverse: 1999 | Prydwen Institute"
    description="Everything you need to know about building teams in Reverse: 1999 -both early and meta teams."
    game="reverse"
  />
);
